var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container py-4"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"row align-items-md-stretch"},[_c('div',{staticClass:"col-md-6"},[(!_vm.isSignedIn)?_c('div',{staticClass:"h-100 p-5 text-bg-dark rounded-3 mb-2"},[_c('h2',[_vm._v("Energize the enviroment")]),_c('p',[_vm._v("Give your users an even better experience in your establishment or at your event by creating easier ways to connect and utilize the space")]),_c('router-link',{staticClass:"btn btnAccent me-2",attrs:{"to":"/Login"}},[_vm._v("Sign Up Now!")])],1):_c('div',{staticClass:"h-100 p-5 text-bg-dark rounded-3 mb-2"},[_c('h2',[_vm._v("Setup and manage your business!!")]),_c('p',[_vm._v("We offer a free service to help you setup your business but you are free to setup the data yourself. Please click the link to see the instructions for bar setup and usage.")]),_c('router-link',{staticClass:"btn btnAccent me-2",attrs:{"to":"/Instructions"}},[_vm._v("Instructions")])],1)]),_vm._m(4)]),(_vm.isSignedIn && _vm.isBusiness)?_c('div',{staticClass:"h-100 p-5 text-bg-dark rounded-3 mt-3"},[_c('EmployeeCode')],1):_vm._e(),_vm._m(5),_c('footer',{staticClass:"pt-3 mt-4 text-muted border-top"},[_vm._v(" © 2024 ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('p',{staticClass:"image-text"},[_vm._v("Enhance the experience")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 description-box"},[_c('i',{staticClass:"ph-bold ph-martini main-icon my-2"})]),_c('div',{staticClass:"col-4 description-box"},[_c('i',{staticClass:"ph-bold ph-chat-circle-dots main-icon my-2"})]),_c('div',{staticClass:"col-4 description-box"},[_c('i',{staticClass:"ph-bold ph-music-notes main-icon my-2"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 description-box"},[_c('p',[_vm._v(" Order a drink from anywhere in the bar and avoid the congested lines. Also give instant access to bartender in VIP sections without having to wait for them to come check on you. ")])]),_c('div',{staticClass:"col-4 description-box"},[_c('p',[_vm._v("With our in app chat features you will be able to discover a new way to mix and mingle in the club, or immediately talk to bouncers in case of emergencies")])]),_c('div',{staticClass:"col-4 description-box"},[_c('p',[_vm._v(" Engage with the DJ's who are out of reach and request songs/ give feedback. Give your users some control over the experience they wanna have ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-4 description-box"}),_c('div',{staticClass:"col-4 description-box"},[_c('h3',[_vm._v("Coming Soon")])]),_c('div',{staticClass:"col-4 description-box"},[_c('h3',[_vm._v("Coming Soon")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 top-margin-mobile"},[_c('img',{staticClass:"image-visual mb-2 h-100 rounded-3",attrs:{"src":require("../assets/Visual.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 p-5 rounded-3 contact-us mt-4"},[_c('h2',[_vm._v("Contact Us")]),_c('p',[_vm._v("If you have any questions or need assistance, please feel free to contact us:")]),_c('p',[_c('strong',[_vm._v("Email:")]),_vm._v(" "),_c('a',{attrs:{"href":"mailto:info@example.com"}},[_vm._v("pulse.up.the.party@gmail.com")])]),_c('p',[_c('strong',[_vm._v("Phone:")]),_vm._v(" +1 (443) 509-1389 ")]),_c('p',[_vm._v(" We're available to schedule appointments to set up your business and provide software training. Please contact us to arrange a suitable time. ")]),_c('p',[_vm._v("We're here to help!")])])
}]

export { render, staticRenderFns }