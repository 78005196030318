<template>
  <div class="hello screen-spacing">
    <div class="">
        <div class="h1 display-inline">Table List</div>
        <button v-if="venueId!=0 && isBusiness" type="button" ref="action"  class="btn mb-3" data-bs-toggle="modal" data-bs-target="#newTableModal"> <i class="fa-solid fa-plus"></i></button>
    </div>
    <SelectVenue ref="venueDropDown" @updateVenue="updateVenue"/>
    <div class="row mt-4">
        <div v-for="(item,index) in tableList" :key="index" class="card m-3" style="width: 18rem;">
  <div class="card-body">
    <h5 class="card-title mt-1">{{item.name}}</h5>
    <h6 class="card-sub-title text-muted mt-1">{{item.description}}</h6>
    <div class="btn-group" role="group">
       <button v-if="item.is_disabled" v-on:click="toggleEnabled(item)" type="button" class="btn btn-primary mx-1">Disabled</button>
        <button v-if="!item.is_disabled" v-on:click="toggleEnabled(item)" type="button" class="btn btn-primary mx-1">Enabled</button>
        <button v-if="isBusiness" type="button" v-on:click="openModal(item)" class="btn btn-primary mx-1">Edit</button>
        <button v-if="isBusiness" type="button" v-on:click="deleteTable(item._id,index)" class="btn btn-primary mx-1">Delete</button>

        

    </div>
  </div>
</div>
   </div>
    

<!-- Modal -->
<div class="modal fade" id="newTableModal" ref="modal" tabindex="-1" aria-labelledby="newTableModalLabel" aria-hidden="true">
  <AddNewTable :table="currentTable" :startingValue="tableList.length" :venueId ="venueId" @addNewTable="addNewTable" @refreshEdit="refreshEdit" @updateTable="updateTable" />
</div>

  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
import AddNewTable from "../../components/modals/AddNewTable.vue"
import tableService from "../../api/table.js"
import SelectVenue from "../../components/SelectVenue.vue"
import swal from 'sweetalert';


export default {
  name: 'TableCreation',
  components:{
   AddNewTable,
   SelectVenue
  },
  data() {
    return {
        newItemType:"",
        tableList:[],
        currentTable:"" ,
        venueId:0,
    };
  },
  computed:{
    isBusiness(){
      return localStorage.getItem('isBusiness');
    }
  },
  methods:{
    updateTable(item){
      var updateIndex = this.tableList.findIndex((table => table._id === item._id))
      this.tableList[updateIndex].name= item.name
      this.tableList[updateIndex].description= item.description
    },
    refreshEdit(){
      this.currentTable=""
    },
    addNewTable(addedList){
        for(var iter = 0; iter<addedList.length;iter++){
            this.tableList.push(addedList[iter])
        }
    },
    reset(){
      this.tableList=[]
      this.venueId=0
    },
    deleteTable(id,index){
     swal({
    title: 'Are you sure you want to delete this table?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // If confirmed, show a success message
       tableService.deleteTableID(id)
       this.tableList.splice(index,1)
      swal('Your table has been deleted!', {
        icon: 'success',
      });
      // TODO: Perform the actual deletion action here
    } else {
      // Handle the cancellation
      swal('Your table is safe!');
    }
  });
    },
    updateVenue(e){
      if(e.target.value!=0){
              tableService.getAllTables(e.target.value).then(data =>{
        this.tableList = data;
        this.venueId=e.target.value
     })
      }
      else{
        this.reset()
      }
    },
    toggleEnabled(item){
        var toggle = {
          is_disabled:!item.is_disabled
        }
          tableService.updateTableByID(item._id,toggle).then(()=>{
            item.is_disabled=!item.is_disabled
         swal("Table has been updated")
          })

    },
    openModal(item){
      this.currentTable=item;     
      this.$refs.action.click();
    },
    getImg(item){
        return item.image
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
