import http from "./base";

class TableService{
    constructor(){}


    async saveNewTables(tableItems) {
        const url = `tables`;
        var response = "";
        await http
            .post(url, tableItems,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            })

        return response;
    }

    async updateTableByID(id,body) {
        const url = `tables/`+id;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async deleteTableID(id) {
        const url = `tables/`+id;
        var response = "";
        await http
            .delete(url,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }


    async getAllTables(venueID)  {
        const url = `tables/getAll/`+venueID;

        var response = "";
        await http
            .get(url, {
                cache: false
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }



}

let service = new TableService();
export default service;



