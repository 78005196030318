<template>
  <div id="app">
    <MenuBar />
    <router-view class="container"/>
    <div v-if="isLoading" id="loading-overlay">
  <div class="spinner"></div>
</div>
  </div>
</template>

<script>
import MenuBar from "./views/MenuBar.vue"

export default {
  name: 'App',
  components: {
    MenuBar
  },
  data() {
    return{
      isLoading:false
    }
  },
   methods: {
    startLoading() {
      this.isLoading = true;  // Show the overlay
    },
    stopLoading() {
      this.isLoading = false; // Hide the overlay
    },
  }
  
  
}
</script>


<style>
  @import './assets/app.css';
 
 #loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>