<template>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><slot></slot></h5>
                 <button type="button" v-on:click="clear" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mt-3">
                    <label for="exampleInputEmail1" class="form-label">Item Name*</label>
                    <input type="text" v-model="itemName" class="form-control">
                </div>
                <div class="mt-3">
                    <label for="exampleInputEmail1" class="form-label">Item Description</label>
                    <textarea class="form-control pl-3" v-model="itemDescription" placeholder="Details of the item" id="floatingTextarea"></textarea>

                </div>
                <div class="mt-3">
                    <label for="exampleInputCost" class="form-label">Cost</label>
                    <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="number" step="0.01" class="form-control" v-model="cost" id="exampleInputCost" aria-label="Amount (to the nearest dollar)">
                    <div class="input-group-append">
                        <span class="input-group-text">{{ formattedCost== 'NaN' ? 0.00:formattedCost }}</span>
                    </div>
                    </div>
                </div>
                <div class="mt-3">
                     <img v-if="previewImage" :src="previewImage" class="uploading-image card-picture-size" />
                    <input type="file" accept="image/jpeg" @change="uploadImage" :v-model="picture">
                </div>
                <div class="btn-group float-right" role="group">
       <button type="button" class="btn btn-sucess" v-on:click="save()"  data-bs-dismiss="modal">Save</button>
       <button type="button" class="btn btn-secondary mx-3" v-on:click="clear" data-bs-dismiss="modal">Cancel</button>
    </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemService from "../../api/menuItems.js"

export default {
     name: 'ItemMenu',
  props:["itemType","editItem","venue_id"],
  data() {
    return{
        itemId:0,
        itemName:"",
        itemDescription:"",
        cost:0.00,
        picture:"",
        previewImage:null,
        isEdit:false,
        currentS3URL:''
    }
  },
  watch:{
    editItem(){
        this.itemName =this.$props.editItem.name
        this.itemDescription=this.$props.editItem.description
        this.cost=this.$props.editItem.cost
        this.isEdit=true
        this.currentS3URL = this.$props.editItem.image  ///whatever the current url is
        this.previewImage = this.$props.editItem.image // reference the current s3 URL
    }
  },
  computed:{
    formattedCost(){
         return parseFloat(this.cost).toFixed(2);
    }
  },
  methods:{
    async updateImg(){
       const url = await ItemService.getSignS3URL(this.picture.type)
        await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: this.picture
      })

      const imageUrl = url.split('?')[0]
      console.log(imageUrl)
      return imageUrl;
    },
    clear(){
        this.itemName =""
        this.itemDescription=""
        this.cost=0.00
        this.isEdit=false
        this.previewImage = null
        this.currentS3URL = ""
        this.picture = ""
        this.$emit("clearEdit")
    },
    async save(){ // might need to clear modal after save
        
        var item= {
            name:this.itemName,
            description:this.itemDescription,
            cost:this.cost,
            image:this.picture !== ""? await this.updateImg() : this.currentS3URL,
            item_type:this.$props.itemType,
            venue_ID:this.$props.venue_id,
            is_disabled:false
        }
        if(!this.isEdit) this.$emit("addItem",item)
        else {
            this.$emit("updateItem",{item:item,id:this.$props.editItem._id})
        }
        this.clear()
    },
    uploadImage(e){
                this.picture = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(this.picture);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);
                };
            }
  }
}
</script>
<style>
  
 </style>