<template>
  <div class="hello screen-spacing">
    <div class="">
        <div class="h1 display-inline">Bathroom List</div>
        <button v-if="venueId!=0 && isBusiness" type="button" ref="action"  class="btn mb-3" data-bs-toggle="modal" data-bs-target="#newbathroomModal"> <i class="fa-solid fa-plus"></i></button>
    </div>
    <SelectVenue ref="venueDropDown" @updateVenue="updateVenue"/>
    <div v-if="venueId!=0" class="row mt-4">
        <div v-for="(item,index) in bathroomList" :key="index" class="card m-3" style="width: 18rem;">
  <div class="card-body">
    <h5 class="card-title mt-1">{{item.name}}</h5>
    <h6 class="card-sub-title text-muted mt-1">{{item.gender}}</h6>

    <p class="card-text text-muted fs-6">{{item.description}}</p>
    <div class="btn-group" role="group">
       <button v-on:click="toggleEnabled(item)" type="button" class="btn btn-primary mx-1">{{item.is_disabled?"Disabled":"Enabled"}}</button>
        <button v-if="isBusiness" type="button" v-on:click="openModal(item)" class="btn btn-primary mx-1">Edit</button>
        <button v-if="isBusiness" type="button" v-on:click="deleteBathroom(item._id,index)" class="btn btn-primary mx-1">Delete</button>

        

    </div>
  </div>
</div>
   </div>
<!-- Modal -->
<div class="modal fade" id="newbathroomModal" ref="modal" tabindex="-1" aria-labelledby="newbathroomModalLabel" aria-hidden="true">
  <AddNewBathroom :bathroom="currentbathroom" :venueId="venueId" @addNewBathroom="addNewBathroom" @updateBathroom="updateBathroom" />
</div>

  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
import AddNewBathroom from "../../components/modals/AddBathroom.vue"
import SelectVenue from "../../components/SelectVenue.vue"
import bathroomService from "../../api/bathroom.js"
import swal from 'sweetalert';

export default {
  name: 'BathroomCreation',
  components:{
   AddNewBathroom,
   SelectVenue
  },
  data() {
    return {
        bathroomList:[],
        currentbathroom:"",
        venueId:0 
    };
  },
  methods:{
    updateBathroom(item){
      var updateIndex = this.bathroomList.findIndex((bar => bar._id === item._id))
      this.bathroomList[updateIndex].name= item.name
      this.bathroomList[updateIndex].gender= item.gender
      this.bathroomList[updateIndex].description= item.description
       this.bathroomList[updateIndex].is_disabled = item.is_disabled
    },
    openModal(item){
      this.currentbathroom=item;
      this.$refs.action.click();
    },
    updateVenue(e){
      if(e.target.value!=0){
        bathroomService.getAllBathrooms(e.target.value).then(data =>{
        this.bathroomList = data;
        this.venueId=e.target.value
     })
      }
      else{
        this.reset()
      }
    },
     deleteBathroom(id,index){
        swal({
    title: 'Are you sure you want to delete this bathroom?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // If confirmed, show a success message
       bathroomService.deleteBathroomID(id).then(()=>{
            this.bathroomList.splice(index,1)
            swal('Your bathroom has been deleted!', {
        icon: 'success',
      });
        })
      
      // TODO: Perform the actual deletion action here
    } else {
      // Handle the cancellation
      swal('Your bathroom is safe!');
    }
  });
    },
    toggleEnabled(item){
        var toggle = {
          is_disabled:!item.is_disabled
        }
          bathroomService.updateBathroomByID(item._id,toggle).then(()=>{
            item.is_disabled=!item.is_disabled
            console.log("Bar has been updated")
          })

    },
    addNewBathroom(item){
      this.bathroomList.push(item)
    }
  },
  computed:{
   isBusiness(){
      return localStorage.getItem('isBusiness');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
