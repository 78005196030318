<template>
  <div class="hello screen-spacing">
    <div class="">
        <div class="h1 display-inline">Generate Barcode</div>
        <button type="button" ref="action"  class="ms-3 btn btnAccent mb-2 mb-3" @click="generateQrCode(currentVenueId)">Generate</button>
        <SelectVenue ref="venueDropDown" @updateVenue="updateVenue"/>
    </div>
    <p class="mt-3">Print your own Barcodes?     <input type="checkbox" class="btn pl-3 ml-2" v-model="showCodes" id="TableName" aria-describedby="emailHelp"></p>

  <div class="barcode-container" v-if="!showCodes && qrcodeString">
        <figure v-if="qrcodeString" class="barcode-container">
        <h1>{{this.currentVenueName.venue_name}}</h1>
        <img  :src="qrcodeString" class="uploading-image card-picture-size barcodeBorder" />
        </figure>
  
    <div class="row">
          <div
            v-for="(table, index) in tableList"
            :key="index"
            class="col-12 col-md-6 col-lg-4 d-flex justify-content-center"
          >
            <figure class="barcode-container text-center card-border">
              <img
                :src="table.qrcodeString"
                class="uploading-image card-picture-size barcodeBorder"
              />
              <figcaption>Scan to Order at {{ table.name }}</figcaption>
            </figure>
          </div>
      </div>
  </div>

  <div class="barcode-container" v-if="showCodes && qrcodeString">
        
        <h1>{{this.currentVenueName.venue_name}}:{{currentVenueId}}</h1>
        
  
    <div class="row">
          <div
            v-for="(table, index) in tableList"
            :key="index"
            class="fs-3 mt-3"
          >
          <div>{{table.name}}: {{currentVenueId + '||' + table._id}}</div>
          </div>
      </div>
  </div>



  </div>


</template>

<script>
import SelectVenue from "../../components/SelectVenue.vue"
import tableService from "../../api/table.js"
const qr = require("qrcode")

export default {
  name: 'GenerateQRCode',
  components:{
   SelectVenue
  },
  data() {
    return {
        newItemType:"",
        currentVenueId:"",
        currentVenueName:"",
        barList:[{Name:"RoofTop",Address:"",Description:"Immediatly on your left when entering",isEnabled:false}],   
        qrcodeString:"",
        tableList:[],
        showCodes: false
    };
  },
  methods:{
    async updateVenue(e){
      if(e.target.value!=0){
         var vue = this;
        this.currentVenueId = e.target.value
        this.qrcodeString = await vue.generateQrCode(e.target.value)
        console.log(this.qrcodeString)
        this.currentVenueName = this.venueList.find(item => item._id === this.currentVenueId)
        tableService.getAllTables(e.target.value).then(async data =>{
          const tableListWithQrCodes = await Promise.all(
    data.map(async item => {
      const qrcodeString = await vue.generateQrCode(this.currentVenueId + '||' + item._id);
      return { 
        ...item, 
        qrcodeString 
      };
    })
  );
        this.tableList = tableListWithQrCodes
        console.log(this.tableList)
     })
      }
      else{
        this.reset()
      }
    },
    async generateQrCode(codeString) {
  return new Promise((resolve, reject) => {
    qr.toDataURL(codeString, function(err, code) {
      if (err) {
        console.log("There was an issue with barcode");
        reject(err); // Reject the promise if there's an error
      } else {
        console.log(code);
        resolve(code); // Resolve the promise with the QR code
      }
    });
  });
},
    openModal(item){
      this.currentBar=item;
      this.$refs.action.click();
    },
    getImg(item){
        return item.image
    }
  },
  computed:{
     venueList(){   
      if(this.$store.state.venues.length>0){
        var dataList = this.$store.state.venues.slice(0);
      dataList.unshift({_id:0,venue_name:"Please Select a Venue"});
      return dataList;
      }   
      return []
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.barcode-container {
  widows: 500px;
  text-align: center; /* Center the content horizontally */
  margin: 50px; /* Add space around the container */
}

.barcodeBorder {
    margin: 50px;
    padding: 15px;
    border:5px solid purple;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}
.barcodeBorder {
  padding: 15px;
  border: 5px solid purple;
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
}

.barcode-subtext {
  margin-top: 10px; /* Add spacing between the image and text */
  font-size: 14px; /* Adjust text size */
  color: purple; /* Match border color for consistency */
  text-align: center;
}

.card-border {
   border: 1px solid lightgray;
   padding: 10px;
}
</style>
