<template>
    <div class="container py-4">
      <div class="background">
          <p class="image-text">Enhance the experience</p>
      </div>
    
    <div class="row">
      <div class="col-4 description-box">
        <i class="ph-bold ph-martini main-icon my-2"></i>
      </div>
      <div class="col-4 description-box">
           <i class="ph-bold ph-chat-circle-dots main-icon my-2"></i>
      </div>
      <div class="col-4 description-box">
          <i class="ph-bold ph-music-notes main-icon my-2"></i>
      </div>

    </div>

    <div class="row">
      <div class="col-4 description-box">
        <p>
          Order a drink from anywhere in the bar and avoid the congested lines. Also give instant access to bartender in VIP sections without having to wait for them to come check on you.
        </p>
      </div>
      <div class="col-4 description-box">
        <p>With our in app chat features you will be able to discover a new way to mix and mingle in the club, or immediately talk to bouncers in case of emergencies</p>
      </div>
      <div class="col-4 description-box">
          <p>
            Engage with the DJ's who are out of reach and request songs/ give feedback. Give your users some control over the experience they wanna have
          </p>
      </div>

    </div>

        <div class="row mb-4">
      <div class="col-4 description-box">

      </div>
      <div class="col-4 description-box">
        <h3>Coming Soon</h3>
      </div>
      <div class="col-4 description-box">
          <h3>Coming Soon</h3>
      </div>

    </div>

    <div class="row align-items-md-stretch">
      <div class="col-md-6">
        <div v-if="!isSignedIn" class="h-100 p-5 text-bg-dark rounded-3 mb-2">
          <h2>Energize the enviroment</h2>
          <p>Give your users an even better experience in your establishment or at your event by creating easier ways to connect and utilize the space</p>
         <router-link class="btn  btnAccent me-2" to="/Login">Sign Up Now!</router-link>
        </div>
        <div v-else class="h-100 p-5 text-bg-dark rounded-3 mb-2">
          <h2>Setup and manage your business!!</h2>
          <p>We offer a free service to help you setup your business but you are free to setup the data yourself. Please click the link to see the instructions for bar setup and usage.</p>
         <router-link class="btn  btnAccent me-2" to="/Instructions">Instructions</router-link>
        </div>
      </div>
      <div class="col-md-6 top-margin-mobile">
          <img class="image-visual mb-2 h-100 rounded-3" src="../assets/Visual.jpg" />
      </div>
    </div>
     <div v-if="isSignedIn && isBusiness" class="h-100 p-5 text-bg-dark rounded-3 mt-3">
        <EmployeeCode/>
      </div>

    <div class="h-100 p-5 rounded-3 contact-us mt-4">
           <h2>Contact Us</h2>

    <p>If you have any questions or need assistance, please feel free to contact us:</p>

    <p>
        <strong>Email:</strong> <a href="mailto:info@example.com">pulse.up.the.party@gmail.com</a>
    </p>

    <p>
        <strong>Phone:</strong> +1 (443) 509-1389
    </p>

    <p>
        We're available to schedule appointments to set up your business and provide software training. Please contact us to arrange a suitable time.
    </p>

    <p>We're here to help!</p>
        </div>

    <footer class="pt-3 mt-4 text-muted border-top">
      &copy; 2024
    </footer>
  </div>

</template>
<script>
import EmployeeCode from './EmployeeCode.vue';

export default {
  components:{EmployeeCode},
  name: 'MainLanding',
  props: {
    msg: String,
    reload:Boolean
  },
  mounted(){
   if(!this.$store.state.loadedVenues && this.isSignedIn){
       this.$store.dispatch('venueLookup')
    }
  },
  computed:{
    isSignedIn(){
      return localStorage.getItem('token')!=null;
    },
    isBusiness(){
      return localStorage.getItem('isBusiness');
    }
  }
}
</script>