<template>
    <div class="modal-dialog">
    <div v-if="this.update" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newTableModalLabel">Update Table</h5>
        <button type="button" v-on:click="clear" class="btn-close" data-bs-dismiss="modal" ></button>
      </div>
      <div class="modal-body">
        <div class="mt-3">
        <label for="TableName" class="form-label">Table Name</label>
    <input type="text" class="form-control pl-3" v-model="name" id="TableName" aria-describedby="emailHelp">
  </div>
   <div class="mt-3">
            <label for="TableName" class="form-label w-100">Is VIP?</label>
            <input type="checkbox" class="btn pl-3" v-model="isVIP" id="TableName" aria-describedby="emailHelp">
        </div>
  <div class="mt-3">
        <label for="Details" class="form-label">Location Details</label>
        <textarea class="form-control pl-3" v-model="locationDetails" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
  </div>
      </div>
      <div class="modal-footer">
        <button type="button" v-on:click="saveItem()"  class="btn btn-primary">Save changes</button>
      </div>
    </div>
    <div v-else class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title" id="newTableModalLabel">Add Tables</h5>
        <button type="button" v-on:click="clear" class="btn-close" data-bs-dismiss="modal" ></button>
      </div>
       <div class="modal-body">

        <div class="mt-3">
            <label for="TableName" class="form-label">How Many Tables?</label>
            <input type="text" class="form-control pl-3" v-model="numberOfTables" id="TableName" aria-describedby="emailHelp">
        </div>

        <div class="mt-3">
            <label for="TableName" class="form-label w-100">VIP Tables?</label>
            <input type="checkbox" class="btn pl-3" v-model="isVIP" id="TableName" aria-describedby="emailHelp">
        </div>
       
       </div>
        <div class="modal-footer">
        <button type="button" v-on:click="clear" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" v-on:click="saveItem()"  class="btn btn-primary">Save changes</button>
      </div>


    </div>
    <button type="button" ref="close" v-show="false"  class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  </div>
</template>

<script>
//import newMenuItem from "../components/modals/newMenuItem.vue"
import tableService from "../../api/table.js"
import swal from 'sweetalert';

export default {
  name: 'AddNewTable',
  props:["table","venueId","startingValue"],
  data() {
    return {
        name:"",
        locationDetails:"",
        numberOfTables:0,
        isVIP:false,
        update:false
    };
  },
  methods:{
    saveItem(){
        
        if(!this.update){
            const number = Number(this.numberOfTables)
            if(!(typeof number === 'number' && Math.floor(this.numberOfTables) === number && number> 0)){
                swal("Ensure the number of tables is a whole number greater than zero.", {
          icon: "error", // You can customize the icon (info, success, error, warning, question)
        })
                return;
            }
          var tableInfo = {
            venue_ID: this.$props.venueId,
            isVIP:this.isVIP,
            startingValue: this.$props.startingValue,
            numberOfTables: number
          }
          tableService.saveNewTables(tableInfo).then(data=>{
          this.$emit("addNewTable",data)
          this.clear()
        })
        }
        else{

        var table = {
          name:this.name,
          venue_ID: this.$props.venueId,
          description: this.locationDetails,
          is_disabled:false,
          isVIP:this.isVIP,
        }

          tableService.updateTableByID(this.$props.table._id,table).then(data=>{
          this.$emit("updateTable",data)
          this.clear()
        })
        }
        
    },
    clear(){
      this.name = ""
      this.locationDetails = ""
      this.numberOfTables = ""
      this.update = false
      this.isVIP = false
      this.$emit("refreshEdit")
      this.$refs.close.click();
    }
  },
  watch:{
        table(){
         if(this.$props.table!=""){
            this.name=this.$props.table.name
            this.locationDetails= this.$props.table.description
            this.update=true;
            this.isVIP=this.$props.table.is_vip
        }
        }
  }
}
</script>
