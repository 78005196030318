import http from "./base";

class ItemService{
    constructor(){}


    async saveNewItem(body) {
        const url = `items`;
        var response = "";
        await http
            .post(url, body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            })

        return response;
    }

    async getSignS3URL(picture) {
        const url = `items/`;
        var response = "";
        await http
            .get(url, {
                params:{
                    type:picture
                },
                headers:{
                    authorization: 'Bearer ' + localStorage.getItem('token')  
                },
                cache: false
            },)
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    
    async updateItemByID(id,body) {
        const url = `items/`+id;
        var response = "";
        await http
            .patch(url,body,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }

    async deleteItemByID(id) {
        const url = `items/`+id;
        var response = "";
        await http
            .delete(url,{
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                  },
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }


    async getAllItems(venueID)  {
        const url = `items/getAllItems/`+venueID;

        var response = "";
        await http
            .get(url, {
                cache: false
            })
            .then(responseData => {
                response = responseData.data.data;
            })
            .catch(error => {
                response = error;
            });

        return response;
    }



}

let service = new ItemService();
export default service;



