<template>
<div class="hello screen-spacing">
    <h1>Current Orders</h1>
       <SelectVenue class="mb-3" ref="venueDropDown" @updateVenue="updateVenue"/>
       <SelectBar v-if="venue_id!=''" :venueId="venue_id" :liveOrder='true' class="mb-3" ref="venueDropDown" @updateBar="updateBar" />

    <div class="row header" v-if="bar_id!=''" style="background-color: purple;">
      <div class="col-md-2">-</div>
      <div class="col-md-3">Order_ID</div>
      <div class="col-md-1"># of items</div>
      <div class="col-md-3">Cost</div>
      <div class="col-md-3">Time of order</div>
    </div>
    <div class="row py-2" v-for="(order, index) in orders" :key="index" :class="{ 'contact-us': order.is_accepted==true }">
      <div class="col-md-2">
        <button  type="button" class="btn text-bg-dark me-2"  v-on:click="openModal(order)">Open</button>
        <button v-if="order.is_accepted==true" type="button" class="btn btnSubmit"  v-on:click="completeOrder(order)">Complete</button>
        </div>
      <div class="col-md-3">{{ order.readable_ID }}</div>
      <div class="col-md-1">{{ order.number_of_items }}</div>
      <div class="col-md-3">{{ toDollarAmount(order.total_cost) }}</div>
      <div class="col-md-3">{{ formatDate(order.createdAt) }}</div>
    </div>
    <!-- Modal -->
<div class="modal fade" id="orderListModal" ref="modal" tabindex="-1" aria-labelledby="orderList" aria-hidden="true">
  <CheckoutOrder :order="currentOrder" @completeOrder="completeOrder" @acceptOrder="acceptOrder" @cancelOrder ="cancelOrder"/>
</div>
<button v-show="false" type="button" ref="action" data-bs-toggle="modal" data-bs-target="#orderListModal" > <i class="fa-solid fa-plus"></i></button>
  </div>

  
</template>

<script>
import SelectVenue from "../../components/SelectVenue.vue"
import SelectBar from "../../components/SelectBar.vue"
import CheckoutOrder from "../../components/modals/ViewOrder.vue"
import orderService from "../../api/orders.js"
import Vue from 'vue'
import swal from 'sweetalert';
export default {
  components: {
    CheckoutOrder,
    SelectVenue,
    SelectBar
  },
  data() {
    return {
      orders: [],
      venue_id: "",
      bar_id: "",
      socket: null, // Initialize socket variable
      newOrdersWaiting:false,
      loading:false,
      currentOrder:"",
      intervalId: null,
    };
  },
  mounted() {
    // Connect to WebSocket server
    

    // Periodically reload data every 2 minutes
    this.intervalId = setInterval(() => {
      this.reloadData();
    }, 600000); // 2 minutes in milliseconds
  },
  methods: {
   dateToTime(date){ 
      return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });},
    toDollarAmount(value){
      return '$' + (value*.01).toFixed(2)
    },
    formatDate(rawDate){
     // var date = new Date(rawDate);
      const dateString =rawDate;
      const localDate = new Date(dateString);


      return this.dateToTime(localDate);
    },
    async acceptOrder(e){
      await orderService.acceptOrder(e._id, e).then(data =>{
        console.log('davion accepts')
        console.log(data)// if success
        if(data._id != null){  
          const index = this.orders.findIndex(item => item._id === e._id);
          console.log(index)
          console.log(this.orders[index])
          Vue.set(this.orders, index, { ...this.orders[index], is_accepted: true });
        }
        
       });
    },
    async completeOrder(e){
      swal({
  title: 'Are you sure you want to complete this order',
  text: 'This means the customer has successfully received their order',
  buttons: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  closeOnClickOutside: false,
}) .then(async(value) => {
  if(value){
    await orderService.completeOrder(e._id, e).then(data =>{
        console.log(data)// if success
         if(data._id != null){
            this.orders = this.orders.filter(item => item._id !== e._id);
        swal('Your order completed!', {
        icon: 'success',
      });
         }
       });
  }
})
      
    },
    async cancelOrder(e){
      //todo Set the object body to just have cancel reason and the update_ID
       await orderService.cancelOrder(e.payment_intent_ID, e).then(data =>{
        console.log(data)
        console.log(data._id != null)
        if(data._id != null){
          console.log('deleted');
          swal('Your order has been deleted!', {
        icon: 'success',
      });
           this.orders = this.orders.filter(item => item._id !== e._id);
        }
         
       });
    },
    openModal(item){
      this.currentOrder=item;
      this.$refs.action.click();
    },
    updateVenue(e) {
      this.venue_id = e.target.value;
      this.bar_id = '';
      // ItemService not defined in the provided code, make sure it's imported or defined
      // ItemService.getAllItems(this.venue_id).then(data => {
      //   this.menu = data;
      // });
    },
    async updateBar(e){
      if(this.bar_id!=''){
        this.socket.send(JSON.stringify({ event: 'closeConnection', data: this.bar_id }));
        this.socket.close();
      }
      this.bar_id = e.target.value
      console.log(typeof(this.bar_id))
      this.socket = new WebSocket(`${process.env.VUE_APP_WEB_SOCKET_URL}?entityId=${this.bar_id}`);

    // WebSocket open event
    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened:'+event);

      // Send a message to the server
      this.socket.send('Hello from Vue.js!');
    });

    // WebSocket message event
    this.socket.addEventListener('message', (event) => {
      const data = JSON.parse(JSON.parse(event.data));
      console.log(data)

      // Handle different events
      switch (data.event) {
        case 'newOrder': {
          console.log('Dave in the switch')
          if (this.orders.length < 10) {
            this.orders.push(data.order);
          } else {
            this.newOrdersWaiting = true;
          }
          break;
        }
        case 'cancelOrder': {
          console.log('Cancelled Order:')
          console.log(data.event)
          const orderIdToRemove = data.order._id;
          const indexToRemove = this.orders.findIndex(item => item._id === orderIdToRemove);
0
          // Check if the item was found
          if (indexToRemove !== -1) {
            // Remove the item using splice
            this.orders.splice(indexToRemove, 1);
            console.log(`Order with ID ${orderIdToRemove} cancelled.`);
          } else {
            console.log(`Order with ID ${orderIdToRemove} not found.`);
          }
          break;
        }
        case 'orderUpdate':
          console.log(`Item updated`);
          break;
        default:
          // Handle other events if needed
          break;
      }

    });

    // WebSocket close event
    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed:' + event);
    });
    console.log("Get the orders")
    await orderService.getAllPendingOrders(this.venue_id, this.bar_id).then(data =>{
      console.log("recieved:")
      console.log(data)
      this.orders = data
    });



    },
    async reloadData() {
      if(this.bar_id != ''){        
       await orderService.getAllPendingOrders(this.venue_id, this.bar_id).then(data =>{
         this.orders = data
       });
      }
    },
  },
  computed:{

  },
  beforeDestroy() {
    // Close WebSocket connection when the component is destroyed
    if (this.socket) {
      this.socket.close();
    }
     clearInterval(this.intervalId);
  },
};
</script>


<style>
.header {
  font-weight: bold;
  color: white;
}
</style>