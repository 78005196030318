import axios from "axios";
import swal from 'sweetalert';


/*const http = axios.create({
    baseURL: 'https://foobar-app-backend.herokuapp.com/',
    timeout: 1000
  });*/

  const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, 
    timeout: 25000
  });


  http.interceptors.request.use(config => {
    // Access the root Vue instance and call the `startLoading` method
    console.log('Root instance:', window.VueInstance.$root);
    if (window.VueInstance && window.VueInstance.$root) {
      window.VueInstance.$children[0].startLoading();
    }
    return config;
  }, error => {
    if (window.VueInstance && window.VueInstance.$root) {
      window.VueInstance.$children[0].stopLoading();
    }
    return Promise.reject(error);
  });

  http.interceptors.response.use(
    (response) => {
      if (window.VueInstance && window.VueInstance.$root) {
        window.VueInstance.$children[0].stopLoading();
      }
      return response
    },
    (error) => {
      if (window.VueInstance && window.VueInstance.$root) {
        window.VueInstance.$children[0].stopLoading();
      }
      if (error.response?.status === 303) {
        return Promise.resolve(error.response);
      }
      if (error.response?.status ==409) {
        console.log(error)
        swal(error.response.data.message, {
          icon: "info", // You can customize the icon (info, success, error, warning, question)
        });
        return Promise.resolve(error.response);
      }
    
      if (error.response?.status >=400) {
        swal('Something has gone wrong, Please refresh and try again. If issue persists, please call 443-509-1389 or email.', {
          icon: "error", // You can customize the icon (info, success, error, warning, question)
        });
      }
      
      return Promise.reject(error);
    }
  );


  export default http;