<template>
  <div class="terms-of-service">
    <h1>Terms of Service for Pulse</h1>
    <p><strong>Effective Date:</strong> November 3, 2024</p>

    <p>Welcome to Pulse! By accessing or using our website, you agree to comply with and be bound by the following Terms of Service. Please read these terms carefully.</p>

    <h2>1. Acceptance of Terms</h2>
    <p>By accessing and using the services provided by Pulse, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree with any part of these terms, please discontinue using our site.</p>

    <h2>2. Use of the Site</h2>
    <p>You may use the site for lawful purposes only. You agree not to engage in any activities that could harm, disrupt, or limit the functionality of our website or infringe on the rights of other users.</p>

    <h2>3. Account Registration and Security</h2>
    <p>When creating an account with Pulse, you agree to provide accurate and complete information. You are responsible for maintaining the security of your account and for all activities conducted under your account. Pulse reserves the right to suspend or terminate accounts that violate these terms.</p>

    <h2>4. Payments and Subscriptions</h2>
    <p>We use <strong>Stripe</strong> to process all payments securely. By providing payment information, you agree to be bound by Stripe’s terms and conditions. Pulse does not store sensitive payment details on its servers, as Stripe handles all payment data directly. For more information on how Stripe processes data, please refer to <a href="https://stripe.com/privacy" target="_blank">Stripe’s Privacy Policy</a>.</p>

    <h2>5. Intellectual Property</h2>
    <p>All content on this website, including text, graphics, logos, and software, is the property of Pulse or its content suppliers and is protected by copyright, trademark, and other intellectual property laws. Unauthorized use or reproduction of our content is prohibited.</p>

    <h2>6. User Content and Conduct</h2>
    <p>You may post content on our site, but you agree not to post any content that:</p>
    <ul>
      <li>Violates any applicable law or regulation.</li>
      <li>Is harmful, offensive, or infringes on others’ rights.</li>
      <li>Contains viruses or any other harmful components.</li>
    </ul>
    <p>Pulse reserves the right to remove or modify content that violates these terms.</p>

    <h2>7. Account Termination</h2>
    <p>You may delete your account and all personal information stored by us at any time by contacting us at <a href="mailto:pulse.up.the.party@gmail.com">pulse.up.the.party@gmail.com</a>. Upon account deletion, you will no longer have access to services associated with your account.</p>

    <h2>8. Limitation of Liability</h2>
    <p>Pulse shall not be liable for any indirect, incidental, special, or consequential damages resulting from the use or inability to use our services, including but not limited to damages for lost profits, data, or other intangible losses.</p>

    <h2>9. Indemnification</h2>
    <p>You agree to indemnify and hold harmless Pulse, its affiliates, and employees from any claim or demand, including reasonable attorney’s fees, arising out of your use of our site, your violation of these terms, or infringement of any rights of another person or entity.</p>

    <h2>10. Changes to Terms</h2>
    <p>Pulse reserves the right to modify these Terms of Service at any time. We will notify you of any material changes by updating the "Effective Date" above. Continued use of our site after such changes indicates your acceptance of the new terms.</p>

    <h2>11. Contact Us</h2>
    <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:pulse.up.the.party@gmail.com">pulse.up.the.party@gmail.com</a>.</p>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped>
.terms-of-service {
  padding: 1rem;
  line-height: 1.6;
}
.terms-of-service h1, .terms-of-service h2 {
  color: #333;
}
.terms-of-service a {
  color: #007bff;
  text-decoration: none;
}
.terms-of-service a:hover {
  text-decoration: underline;
}
</style>
