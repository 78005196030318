import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router/router'
import store from './store'
import 'bootstrap' // ←
import "./assets/app.css"
import "./assets/fontawesome-free-6.2.1-web/css/fontawesome.css"
import "./assets/fontawesome-free-6.2.1-web/css/brands.css"
import "./assets/fontawesome-free-6.2.1-web/css/solid.css"
import "@phosphor-icons/webcomponents";
import http from "./api/base"


import'bootstrap/dist/css/bootstrap.css'


Vue.use(VueRouter)
// Optionally install the BootstrapVue icon components plugin
Vue.config.productionTip = false

Vue.prototype.$axios = http; // Attach the Axios instance globally

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


window.VueInstance = app;