<template>
  <div class="hello screen-spacing">
    <h1>VIP Menu</h1>
      <SelectVenue ref="venueDropDown" @updateVenue="updateVenue"/>
      <div v-if="venue_id!=0">
          <label for="CocktailLaabel" class="form-label text-left w-100 text-start custom-label-height fw-bold fs-5">Cocktails
       <button v-if="isBusiness" type="button" ref="action"  class="btn" data-bs-toggle="modal" data-bs-target="#newItemModal" v-on:click="openModal('Cocktail','Add Cocktail')"><i class="fa-solid fa-plus"></i></button></label>
        <div class="row mt-4">
        <div v-for="(item,index) in cocktailList" :key="index" class="card m-3" style="width: 18rem;">
  <div class="card-body">
    <img :src="item.image" class="card-picture-size" :alt="item.name">
    <h5 class="card-title mt-1">{{item.name}}</h5>
    <p class="card-text text-muted fs-6">${{item.cost}}</p>
    <p class="card-text fixedTextBox">{{item.description==""?"N/A":item.description}}</p>
    <div class="btn-group" role="group">
       <button type="button" v-on:click="toggleEnable(item)" class="btn btn-primary me-2">{{item.is_disabled?"Disabled":'Enabled'}}</button>
       <button v-if="isBusiness" type="button" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#newItemModal" v-on:click="openEditModal(item,'Cocktail')">Edit</button>
       <button v-if="isBusiness" type="button" v-on:click="deleteItem(item)" class="btn btn-primary">Delete</button>
    </div>
  </div>
</div>
   </div>
   <div class="mt-4">
       <label for="wineLabel" class="form-label text-left w-100 text-start custom-label-height fw-bold fs-5">Wine
       <button v-if="isBusiness" type="button" ref="action"  class="btn" data-bs-toggle="modal" data-bs-target="#newItemModal" v-on:click="openModal('Wine','Add Wine')"><i class="fa-solid fa-plus"></i></button></label>

    <div class="row mt-4">
        <div v-for="(item,index) in wineList" :key="index" class="card m-3" style="width: 18rem;">
  <div class="card-body">
    <img :src="item.image" class="card-picture-size" alt="Italian Trulli">
    <h5 class="card-title mt-1">{{item.name}}</h5>
    <p class="card-text text-muted fs-6">${{item.cost}}</p>
    <div class="btn-group" role="group">
      <button type="button" v-on:click="toggleEnable(item)" class="btn btn-primary me-2">{{item.is_disabled?"Disabled":'Enabled'}}</button>
       <button v-if="isBusiness" type="button" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#newItemModal" v-on:click="openEditModal(item,'Wine')">Edit</button>
       <button v-if="isBusiness" type="button" v-on:click="deleteItem(item)" class="btn btn-primary">Delete</button>
    </div>
  </div>
</div>
   </div>
   </div>
   <div class="mt-4">
           <label for="beerLabel" class="form-label text-left w-100 text-start custom-label-height fw-bold fs-5">Beer
       <button v-if="isBusiness" type="button" ref="action"  class="btn" data-bs-toggle="modal" data-bs-target="#newItemModal" v-on:click="openModal('Beer','Add Beer')"><i class="fa-solid fa-plus"></i></button></label>

    <div class="row mt-4">
        <div v-for="(item,index) in beerList" :key="index" class="card m-3 mb-3" style="width: 18rem;">
  <div class="card-body">
    <img :src="item.image" class="card-picture-size" alt="Italian Trulli">
    <h5 class="card-title mt-1">{{item.name}}</h5>
    <p class="card-text text-muted fs-6">${{item.cost}}</p>
    <div class="btn-group" role="group">
      <button type="button" v-on:click="toggleEnable(item)" class="btn btn-primary me-2">{{item.is_disabled?"Disabled":'Enabled'}}</button>
       <button v-if="isBusiness" type="button" class="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#newItemModal" v-on:click="openEditModal(item,'Beer')">Edit</button>       
       <button v-if="isBusiness" type="button" v-on:click="deleteItem(item)" class="btn btn-primary">Delete</button>
    </div>
  </div>
</div>
   </div>
   </div>
    </div>
<!-- Modal -->
<div class="modal fade" id="newItemModal" ref="modal" tabindex="-1" aria-labelledby="newbathroomModalLabel" aria-hidden="true">
  <NewMenuItem :itemType="newItemType" :venue_id="venue_id" :editItem="editItem" @addItem="addItem" @updateItem="updateItem" @clearEdit="clearEdit">
   {{displayedModalString}}
  </NewMenuItem>
</div>
    
  </div>
</template>

<script>
import NewMenuItem from "../../components/modals/newMenuItem.vue"
import {itemTypes} from "../../assets/staticData"
import ItemService from "../../api/menuItems.js"
import SelectVenue from "../../components/SelectVenue.vue"
import swal from 'sweetalert';

export default {
  name: 'ItemMenu',
  components:{
    NewMenuItem,
    SelectVenue
  },
  data() {
    return {
        newItemType:"",
        displayedModalString:"",
        editItem:"",
        menu:[],
        venue_id:0,
        
    };
  },
  methods:{
    clearEdit(){
      this.editItem=""
    },
    updateVenue(e){
      this.venue_id=e.target.value;
      ItemService.getAllItems(this.venue_id).then(data =>{
        this.menu=data
      })
    },
    openEditModal(item,type){
      this.editItem=item;
      this.newItemType=type;
      this.displayedModalString="Edit "+item.name
    },
    openModal(type,displayString){
      this.newItemType=type;
      this.displayedModalString=displayString
//      this.$refs.action.click();
    },
    async addItem(item){
      ItemService.saveNewItem(item).then(data=>{
        this.menu.push(data)
         swal("save successful")
      })
    },

    async updateItem(data){
      ItemService.updateItemByID(data.id,data.item).then(item=>{
       var updateIndex = this.menu.findIndex((bar => bar._id === item._id))
      this.menu[updateIndex].name= item.name
      this.menu[updateIndex].description= item.description
      this.menu[updateIndex].image =item.image
      this.menu[updateIndex].cost =item.cost
         swal("save successful")
      })
    },
    toggleEnable(item){
        var toggle = {
          is_disabled:!item.is_disabled
        }
        ItemService.updateItemByID(item._id,toggle).then(()=>{
            item.is_disabled=!item.is_disabled
           swal("Item has been updated")
          })

    },
     deleteItem(item){
      const index = this.menu.indexOf(item);
       swal({
    title: 'Are you sure you want to delete the '+ item.name+'?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // If confirmed, show a success message
       ItemService.deleteItemByID(item._id).then(()=>{
            this.menu.splice(index,1)
            swal("delete successful")
        })
      // TODO: Perform the actual deletion action here
    } else {
      // Handle the cancellation
      swal('Your item is safe!');
    }
  });
    }
  },
  computed:{
    cocktailList(){
        return this.menu.filter(item => item.item_type===itemTypes.Cocktail);
    },
    wineList(){
        return this.menu.filter(item => item.item_type===itemTypes.Wine);
    },
    beerList(){
        return this.menu.filter(item => item.item_type===itemTypes.Beer);
    
    },
    liquorList(){
        return this.menu.filter(function(item) {

      return item.Type==itemTypes.Liquor
    });
    
    },
    isBusiness(){
      return localStorage.getItem('isBusiness');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.fixedTextBox{
  height: 100px;
}
</style>
