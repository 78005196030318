<template>
  <div class="privacy-policy">
    <h1>Privacy Policy for Pulse</h1>
    <p><strong>Effective Date:</strong> November 3, 2024</p>

    <p>At Pulse, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you visit our website.</p>

    <h2>1. Information We Collect</h2>
    <p>We may collect the following types of information:</p>
    <ul>
      <li><strong>Personal Information:</strong> When you create an account, make a purchase, or contact us, we may collect personal information such as your name, email address, and other relevant details.</li>
      <li><strong>Non-Personal Information:</strong> This includes information such as your IP address, browser type, and pages visited, collected through cookies and similar tracking technologies.</li>
    </ul>

    <h2>2. Payment Data</h2>
    <p>We have partnered with <strong>Stripe</strong> to securely handle your payment information. Stripe processes your payment data directly, and we do not store sensitive payment details on our servers. For more information on Stripe’s data practices, please refer to <a href="https://stripe.com/privacy" target="_blank">Stripe’s Privacy Policy</a>.</p>

    <h2>3. How We Use Your Information</h2>
    <p>We may use your information to:</p>
    <ul>
      <li>Personalize and improve your experience on our site.</li>
      <li>Process transactions and provide customer support.</li>
      <li>Send updates about your orders and relevant products or services (you may opt out at any time).</li>
    </ul>

    <h2>4. Account Deletion</h2>
    <p>You have the right to delete your account and any associated personal information at any time. To initiate this process, please contact us at <a href="mailto:pulse.up.the.party@gmail.com">pulse.up.the.party@gmail.com</a>, and we will handle your request promptly.</p>

    <h2>5. Security Measures</h2>
    <p>We implement a variety of security measures to maintain the safety of your personal information. Our practices include encrypted data transmission and access control policies to protect against unauthorized access.</p>

    <h2>6. Data Sharing</h2>
    <p>We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties, except as necessary to provide our services, comply with legal obligations, or protect our rights.</p>

    <h2>7. Your Rights</h2>
    <p>Depending on your location, you may have rights regarding your personal information, including the right to access, correct, or delete your data. Please contact us at <a href="mailto:pulse.up.the.party@gmail.com">pulse.up.the.party@gmail.com</a> to exercise these rights.</p>

    <h2>8. Child Safety</h2>
    <p>Pulse is committed to complying with child safety laws. Our platform is not intended for children under the age of 18, and we do not knowingly collect personal information from minors. If we become aware that we have inadvertently collected personal information from a minor, we will take immediate steps to delete that information.</p>

    <h2>9. Underage Drinking Disclaimer</h2>
    <p>Pulse is a technology platform that provides tools for nightlife venues. We are not responsible for underage drinking or illegal activities that may occur in bars or clubs using our system. The responsibility for compliance with local laws, including those related to age restrictions, lies with the venues that use our services.</p>

    <h2>10. Changes to This Privacy Policy</h2>
    <p>Pulse may update this Privacy Policy periodically. We encourage you to review it from time to time to stay informed of any changes.</p>

    <h2>11. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:pulse.up.the.party@gmail.com">pulse.up.the.party@gmail.com</a>.</p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy {
  padding: 1rem;
  line-height: 1.6;
}
.privacy-policy h1, .privacy-policy h2 {
  color: #333;
}
.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}
.privacy-policy a:hover {
  text-decoration: underline;
}
</style>
