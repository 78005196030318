<template>
  <div class="hello screen-spacing">
    <label class="me-2 h4" for="dropdown">Select Bar:</label>
    <select id="dropdown" class="form-select" v-on:change="updateBar($event)">
       <option v-for="(item,index) in barList" :key="index" class="card mx-3" :value="item._id">{{item.name}}</option>
    </select>
  </div>
</template>

<script>
import barService from "../api/bar.js"

export default {
  name: 'SelectBar',
   props: {
      venueId: {
        type: String, // Define the expected type
      },
      liveOrder: {
        type: Boolean, // Define the expected type
        default: false // Provide a default value
      }
  },
    data() {
    return {
      barList: [],
    };
  },
    watch: {
    venueId: {
      immediate: true, // To trigger the watcher immediately on component mount
      handler(newVenueID) {
        // Handle the change in venueID
        this.fetchOrders(newVenueID);
      },
    },
  },
  methods:{
    updateBar(e){
        this.$emit("updateBar",e)
    },
    async fetchOrders(){
         barService.getAllBars(this.$props.venueId).then(data =>{
            this.barList = data
            this.barList.unshift({_id:0,name:"Please Select a Bar"});
            if(this.$props.liveOrder){
              console.log('')
              this.barList.push({_id:this.$props.venueId,name:"Table Orders"})
            }
         }) 
        
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
