<template>
  <div>
    <div v-if="this.$route.path === '/'">
    
    </div>
    <header class="p-3 text-bg-dark">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <div class="text-end"> 
          <router-link class="nav-link px-2 text-white" to="/"><img class="logo" src="../assets/logo.png"/></router-link>
          
          
        </div>

        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          
          <li></li>
          <li></li>
          <li>
            <div class="dropdown">
  <a class="nav-link px-2 text-white dropdown-toggle" v-if="isSignedIn"  href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
   Inventory
  </a>

  <ul class="dropdown-menu">
     <li v-if="isBusiness" ><router-link to="/Venue-List" class="dropdown-item" href="#">Venue List</router-link></li>
    <li><router-link to="/Bar-Creation" class="dropdown-item" href="#">Bar List</router-link></li>
    <li><router-link to="/Menu" class="dropdown-item" href="#">Menu</router-link></li>
    <li><router-link to="/VIP" class="dropdown-item" href="#">VIP Menu</router-link></li>
    <li><router-link to="/Bathroom-Creation" class="dropdown-item" href="#">Bathroom</router-link></li>
    <li><router-link to="/Table-Creation" class="dropdown-item" href="#">Tables</router-link></li>
    <li></li>
  </ul>
</div>
          </li>
          <li>
              <div class="dropdown">
  <a class="nav-link px-2 text-white dropdown-toggle" v-if="isSignedIn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
   Operations
  </a>

  <ul class="dropdown-menu">
     <li><router-link to="/Generate-Barcode" class="dropdown-item" href="#">Generate Barcode</router-link></li>
       <li><router-link v-if="isSignedIn" class="dropdown-item" to="/Live-Orders">Live Orders</router-link></li>
  </ul>
</div>

          </li>

          <li>
              <div class="dropdown">
  <a class="nav-link px-2 text-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
   General Info
  </a>

  <ul class="dropdown-menu">
          <li><router-link class="dropdown-item" to="/PricingPage">Pricing</router-link></li>
          <li><router-link class="dropdown-item" to="/PrivacyPolicy">Privacy Policy</router-link></li>
          <li><router-link class="dropdown-item" to="/TermsOfService">Terms Of Service</router-link></li>  </ul>
</div>



          </li>



          <li><router-link class="nav-link px-2 text-white" to="/About">About</router-link></li>


        </ul>


        <div class="text-end"> 
          
          <router-link class="btn btn-outline-light me-2" to="/Login" v-if="!isSignedIn">Login</router-link>
          <button class="btn me-2 btnAccent" @click="logout()" v-if="isSignedIn">Logout</button>
          <router-link class="btn btnAccent" to="/Signup" v-if="!isSignedIn">Sign-up</router-link>
          <router-link v-if="isSignedIn" class="btn menuIcon me-2 " to="/Accounts"><i class="ph-bold ph-gear menuIcon"></i></router-link>

        </div>
      </div>
    </div>
  </header>
</div>
</template>
<script>

export default {
  name: "MenuBar",
  components: {},
  data() {
    return {
        x:1
    };
  },
  methods: {
    repopulateList(typeAheadValue){
       console.log(typeAheadValue)
    },
    logout(){
      localStorage.removeItem("EmployeeCode");
      localStorage.removeItem("user");
      localStorage.removeItem("businessID");
      localStorage.removeItem("isBusiness");
      localStorage.removeItem("stripeAccountID");
      localStorage.removeItem("token");
      this.$router.push({ name: 'Login',params: { refresh:true}}) 
      this.$store.dispatch('setInitial') 
    },
      },
      computed:{
      isSignedIn(){
        if(localStorage.getItem('token')){
          return true;
        }
        return false;
      },
       isBusiness(){
      return localStorage.getItem('isBusiness');
    }
    }
  };
</script>